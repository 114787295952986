
import { useState } from 'react'
import moment from 'moment'
import dayjs from "dayjs";
import CurrencyInput from 'react-currency-input-field'
import { formatDollarWithSymbol, formatDollar } from '../utils/formats'

const CustomerDetails = ({ rows, handleChange, handleExcludeChange }) => {
  const [updatedTenants, setUpdateTenants] = useState(rows);

  const handleNewRateChange = (tenant, value) => {
    tenant.new_rate = value
    handleChange(tenant, value)
  }

  const showCurrentRateColor = (tenant) => {
    if (tenant.current_rate === tenant.std_rate) {
      return '';
    }

    return tenant.current_rate > tenant.std_rate ? '#2F8046' : '#D80E0E';
  }

  const handleChangeExclude = (tenant, index, value) => {
    updatedTenants[index].exclude_submit = value;
    setUpdateTenants([...updatedTenants])

    tenant.exclude_submit = value;
    handleExcludeChange(tenant, value);
  }

  return (
    <table className="w-full table-tenant">
      <thead className="text-gray-600">
        <tr className="uppercase text-left">
          <th>Tenant Name/Unit</th>
          <th>Unit Type</th>
          <th>
            Current
            <br />
            Tenant Rate
          </th>
          <th>
            Today's
            <br />
            Street Rate
          </th>
          <th>
            Street
            <br />
            Rate Delta
          </th>
          <th>
            Move-Out
            <br />
            Probability
          </th>
          <th>
            Previously <br /> Increased
          </th>
          <th>
            Last
            <br />
            Increase
            <br />
            Date
          </th>
          <th>
            Rate <br /> Increase  %
          </th>
          <th>New Rate</th>
          <th>Exclude</th>
        </tr>
      </thead>
      <tbody>
        {updatedTenants &&
          updatedTenants.map((tenant, index) => (
            <tr key={index}>
              <td style={{ maxWidth: 80 }}>
                {tenant.tenant_fname} {tenant.tenant_lname}
                <br />
                {tenant.unit_no}
              </td>
              <td>{tenant.unit_type}</td>
              <td style={{ color: showCurrentRateColor(tenant) }}>${formatDollar(tenant.current_rate)}</td>
              <td>${formatDollar(tenant.std_rate)}</td>
              <td>{formatDollarWithSymbol(tenant.current_rate - tenant.std_rate)}</td>
              <td>
                <span className="px-5 py-2 bg-green-200 rounded-lg">{(tenant.move_out_probability * 100.0).toFixed(1)}%</span>
              </td>
              <td>{dayjs(tenant.move_in_date).isBefore(dayjs(tenant.last_ecri_date)) ? "Yes" : "No"}</td>
              <td>{moment(tenant.last_ecri_date).format('M/D/YY')}</td>
              <td>{(tenant.increase_percentage * 100.0).toFixed(2)}%</td>
              <td className="w-24">
                {
                  tenant.exclude_submit ? (
                    <span style={{ marginLeft: 16}}>$-</span>
                  ) : <CurrencyInput
                    style={{ width: '90px' }}
                    prefix="$"
                    disableGroupSeparators={true}
                    defaultValue={formatDollar(tenant.new_rate)}
                    decimalsLimit={2}
                    onValueChange={(value) => handleNewRateChange(tenant, value)}
                  />
                }
              </td>
              <td>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={tenant.exclude_submit}
                    onChange={(e) => handleChangeExclude(tenant, index, e.target.checked)}
                  />
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export default CustomerDetails
