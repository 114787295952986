import apiClient from "../utils/apiClient";

const competitorService = {
  async updateCompsByStoreId(storeid, payload) {
    try {
      await apiClient.post(`/comp_stores/${storeid}`, payload);
      alert("StorTrack settings updated successfully.");
      return true;
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error?.response?.data?.errors) {
        const errors = error.response.data.errors[0];
        alert(
          "Failed to update Comps store, storeid: " +
            storeid +
            ", error: " +
            Object.values(errors).join(", ")
        );
      } else {
        alert(
          "Failed to update Comps store, storeid: " +
            storeid +
            ", error: " +
            error.toString()
        );
      }
      return false;
    }
  },
};

export default competitorService;
