import apiClient from "../utils/apiClient";

const portfolioService = {
  async getPortfolioDetail(portfolioId) {
    try {
      const response = await apiClient.get(`/portfolio/${portfolioId}`);
      return response.data.result;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return {};
  },
  async savePortfolioDetail(portfolioId, portfolioDetail) {
    try {
      await apiClient.put(`/portfolio/${portfolioId}`, portfolioDetail);
      alert("Portfolio updated successfully");
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
  async saveEcriSettings(portfolioId, ecriSettings) {
    try {
      await apiClient.put(`/portfolio/ecri/${portfolioId}`, {
        ecri_settings: ecriSettings,
      });
      alert("Settings saved successfully");
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
  async getPortfolios(params) {
    try {
      const response = await apiClient.get("/portfolio", { params });
      return {
        data: response.data.result,
        pagination: response.data.pagination,
      };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return {
        data: [],
        pagination: {},
      };
    }
    return [];
  },
  async listPortfolios() {
    try {
      const response = await apiClient.get("/portfolio/list");
      return response.data.result;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return [];
  },
  async getPortfolioById(portfolioId) {
    try {
      const response = await apiClient.get(`/portfolio/id/${portfolioId}`);
      return response.data.result;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return null;
  },
  async createPortfolioAndUsers(portfolioDetail) {
    try {
      await apiClient.post("/portfolio/add", portfolioDetail);
      alert("Portfolio and users created successfully");
      return true;
    } catch (error) {
      console.log("Error creating portfolio:", error);
      alert(alertError(error));
      return false;
    }

    function alertError(error) {
      let errorMessage = "Error creating portfolio: ";
      if (
        Array.isArray(error?.response?.data?.errors) &&
        typeof error?.response?.data?.errors[0] === "object"
      ) {
        errorMessage += Object.values(
          error?.response?.data?.errors?.[0]
        ).toString();
      } else {
        errorMessage += error?.response
          ? JSON.stringify(error?.response)
          : error.toString();
      }
      return errorMessage;
    }
  },
  async listFacilities(portfolioId) {
    try {
      const response = await apiClient.get(
        `/portfolio/${portfolioId}/facility/list`
      );
      return response.data.result;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return [];
  },
  async lookupStorTrack(payload) {
    try {
      const response = await apiClient.post(
        "/portfolio/lookupStorTrack",
        payload
      );
      return response.data.result.stores;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return [];
  },
};

export default portfolioService;
