import { useNavigate } from 'react-router-dom'
import { useEffect, useContext } from "react";
import { AuthContext } from '../context/authContext'
import apiClient from '../utils/apiClient';

const Loading = () => {
  const navigate = useNavigate();
  const { isAuthenticated, auth } = useContext(AuthContext);

  if (!isAuthenticated) navigate('/sign-in');

  useEffect(() => {
    apiClient.get('/sync-data').then(function (response) {
      console.log(response)
      navigate('/street-rates')
    }).catch(function (error) {
      console.log(error)
    })
  }, [])

  return (
    <div class="loading-container">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px', height: '100%', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
          <img src={'/assets/images/loading.svg'} className="h-5" alt="Light Bulb Icon" style={{ width: "80%", maxWidth: "300px", height: '50%' }} />
          <p>Calculating Your Street Rate Recommendations...</p>
        </div>
      </div>
    </div>
  );
}

export default Loading;
